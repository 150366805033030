import React from 'react';
import TicGame from './TicGame';


const Games= () => {
  return (
<>
<TicGame />
</>
  );
}
export default Games;